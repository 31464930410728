// BACKGROUND COLOR
$bg-blue: #3B82F6;
$bg-green: #10B981;
$bg-red: #EF4444;
$bg-yellow: #F59E0B;
$bg-purple: #8B5CF6;
$bg-grey: #F3F4F6;
$bg-dark-grey: #374151;
$bg-dark: #111827;

// FONTS
$font-poppins: 'Poppins', sans-serif;

// PAGE LOADER
.page-loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

// SELECT SEARCH
.react-select__control--is-focused {
  border: none !important;
  box-shadow: 0 0 0 0.2rem rgba(59, 130, 246, 0.25) !important;
}

.react-select-invalid__control {
  border-color: #EF4444 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23EF4444' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23EF4444' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.react-select-invalid__control--is-focused {
  border-color: #EF4444 !important;
  box-shadow: 0 0 0 0.2rem rgba(239, 68, 68, 0.25) !important;
}

// TEXT EDITOR
.text-editor {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.text-editor.focused {
  border: 1px solid rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0.2rem rgba(59, 130, 246, 0.25) !important;
}

.text-editor.is-invalid {
  border-color: #EF4444 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23EF4444' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23EF4444' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.text-editor.is-invalid.focused {
  border-color: #EF4444 !important;
  box-shadow: 0 0 0 0.2rem rgba(239, 68, 68, 0.25) !important;
}

// MODAL
@media (min-width: 576px) {
  .modal-xl {
    max-width: 95vw !important;
  }
}
@media (min-width: 992px) {
  .modal-xl {
    max-width: 95vw !important;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 95vw !important;
  }
}