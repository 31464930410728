// BACKGROUND COLOR
$bg-blue: #3b82f6;
$bg-blue-light: #0ea5e9;
$bg-green: #10b981;
$bg-red: #ef4444;
$bg-yellow: #f59e0b;
$bg-purple: #6366F1;
$bg-grey: #f3f4f6;
$bg-dark-grey: #374151;
$bg-dark: #111827;

// FONTS
$font-poppins: 'Poppins', sans-serif;

// Layout
.layout-wrapper {
  max-width: 100vw;
  width: 100vw;
  background-color: $bg-grey;
  display: flex;
}

// Backdrop
.backdrop {
  background-color: none;
  transition: margin 0.3s ease-in-out;
}

// Sidebar
.sidebar-wrapper {
  $width: 275px;
  $height: 100vh;

  width: $width;
  min-width: $width;
  max-width: $width;
  height: $height;
  min-height: $height;
  background-color: white;
  transition: margin 0.3s ease-in-out;

  &.collapsed {
    margin-left: -$width;
  }

  &.action {
    width: $width;
    min-width: $width;
    max-width: $width;
    height: $height;
    min-height: $height;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
  }
}
.sidebar-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.sidebar-head {
  display: flex;
  flex-direction: column;
  align-items: center;

  .close {
    top: 0;
    right: 0;
    position: absolute;
    cursor: pointer;
    display: none;
  }
}
.sidebar-body {
  height: 100% ;
  overflow: auto;
}
.sidebar-link {
  color: $bg-dark !important;
  opacity: .7;
  transition: all .2s ease-in-out;

  &:hover {
    opacity: 1;
    background: $bg-grey;
  }
  
  &.active {
    background: $bg-blue;
    color: white !important;
    opacity: 1;
  }
}
.sidebar-link-dropdown {
  color: $bg-dark !important;
  opacity: .7;
  transition: all .2s ease-in-out;

  &:hover {
    opacity: 1;
    background: $bg-grey;
  }
  
  &.active {
    background: $bg-grey;
    opacity: 1;
  }
}

// Navbar
.navbar-wrapper {
  width: 100%;
  height: 70px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  z-index: 10;

  .account-info {
    position: fixed;
    display: block;
    right: 0;
    margin-right: 15px;
  }

  .avatar {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


// Content
.content-wrapper {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  overflow-x: scroll;
}
.content {
  height: 100%;
  margin-top: 95px;
}

// ====================================================== Responsive
// Max width 768px
@media (max-width: 768px) { 
  // Navbar
  .navbar-wrapper {
    .account-info {
      display: none;
    }
  }
}

// Max width 992px
@media (max-width: 991.98px) {
  // Backdrop
  .backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #333;
    opacity: .5;
    z-index: 1000;
    transition: margin 0.3s ease-in-out;
  }

  // Sidebar
  .sidebar-wrapper {
    $width: 275px;
    $height: 100vh;
  
    width: $width;
    min-width: $width;
    max-width: $width;
    height: $height;
    min-height: $height;
    margin-left: -$width;
    background-color: white;
    transition: margin 0.3s ease-in-out;
    position: fixed;
    z-index: 10000;
  
    &.collapsed {
      margin-left: 0;
    }
  
    &.action {
      width: $width;
      min-width: $width;
      max-width: $width;
      height: $height;
      min-height: $height;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
    }
  }
  .sidebar-head {
    .close {
      top: 0;
      right: 0;
      position: absolute;
      cursor: pointer;
      display: block;
    }
  }
}